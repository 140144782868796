<div class="field-wrapper" [class.error]="invalid">
  <label class="field-label" [for]="name">{{ label | translate }}</label>
  <div class="input-wrapper">
    <input
      class="field-input"
      name="name"
      [id]="inputId"
      [placeholder]="placeholder"
      [type]="type"
      [autocomplete]="autocomplete"
      [formControl]="control"
      #input
    />
    <button
      *ngIf="showPasswordButton"
      type="button"
      class="password-button-visible"
      [ngClass]="type"
      (click)="toggleInputType(input)"
    ></button>
  </div>
</div>
