import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  protected params = new URLSearchParams(location.search);

  has(name: string): boolean {
    return this.params.has(name);
  }

  get(name: string, fallback = ''): string {
    return this.has(name) ? this.params.get(name)! : fallback;
  }

  getAllToString(): string {
    return this.params.toString();
  }

  getAllToObject(): Record<string, string> {
    return Object.fromEntries(this.params.entries());
  }
}
