export const QUERY_PARAMS = {
  SOLUTION: 'solution',
  CLIENT_ID: 'client_id',
  RESPONSE_TYPE: 'response_type',
  REDIRECT_URI: 'redirect_uri',

  INVITATION_TOKEN: 'invitation_token',
  RESET_PASSWORD_TOKEN: 'reset_password_token',

  LANG: 'lang',
  STATE: 'state',
};
