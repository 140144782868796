import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-text-field',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFieldComponent {
  @ViewChild('input', { read: ElementRef, static: true })
  input: ElementRef<HTMLInputElement> | undefined;

  @Input()
  name: string = '';

  @Input()
  label: string = '';

  @Input()
  inputId: string = '';

  @Input()
  autocomplete: string = 'off';

  @Input()
  autofocus: string | boolean = false;

  @Input()
  type: 'text' | 'password' = 'text';

  @Input()
  placeholder: string = '';

  @Input()
  showPasswordButton: boolean = false;

  @Input({ required: true })
  control!: FormControl;

  @Input()
  invalid: boolean = false;

  toggleInputType(input: HTMLInputElement): void {
    this.type = this.type === 'text' ? 'password' : 'text';
  }
}
