export const environment = {
  production: false,
  urls: {
    api: 'https://dev.api.admin-panel.leafio.cloud/',
    assets: 'assets/',
  },
  endpoints: {
    login: 'api/login',

    check_invitation_token: 'api/users/invitation/check',
    invitation: 'api/users/invitation',

    check_reset_password_token: 'api/users/reset_password/check',
    reset_password: 'api/users/reset_password',

    oauth_authorize: 'oauth/authorize',
  },
  solutions: {
    global: {
      name: 'Global Admin Panel',
    },
  },
};
